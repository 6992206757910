import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/all',
    name: 'All Shanties',
    component: () => import(/* webpackChunkName: "all" */ '@/views/All.vue')
  },
  {
    path: '/shanty/:filename',
    name: 'Shanty Info',
    component: () => import(/* webpackChunkName: "shanty" */ '@/views/Shanty.vue'),
    props: true
  },
  {
    path: '/shanty/:filename/:versionID',
    name: 'Shanty Lyrics',
    component: () => import(/* webpackChunkName: "Lyrics" */ '@/views/Lyrics.vue'),
    props: true
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "Search" */ '@/views/Search.vue'),
    props: true
  },
  {
    path: '/bookmarks',
    name: 'Bookmarked shanties',
    component: () => import(/* webpackChunkName: "Bookmark" */ '@/views/Bookmarks.vue'),
    props: true
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/Settings.vue'),
    props: true
  },
  {
    path: '/pirates',
    name: 'Pirate',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/Pirate.vue'),
    props: true
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.name + " | Sea Shanty Songbook"
  next();
});

export default router
