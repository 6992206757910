import Vue from 'vue'
import VueMeta from 'vue-meta'
import './registerServiceWorker'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use(VueMeta)

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    document.documentElement.setAttribute('lang', 'en')
  },
  metaInfo() {
    return { 
      title: "Sea Shanty Songbook",
      meta: [
        { name: 'description', content:  'Open Source collection of Sea Shanty lyrics bundled up in a user friendly application'},
        { property: 'og:title', content: "Sea Shanty Songbook"},
        { property: 'og:site_name', content: 'Sea Shanty Songbook'},
        { property: 'og:type', content: 'website'},
        { property: 'og:image', content: '/img/icons/logo.png'},
        {name: 'robots', content: 'index,follow'} 
      ]
    }
  }
}).$mount('#app')
