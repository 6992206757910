import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    settings: {
      theme: 'light'
    },
    lastViewed: [],
    bookmarked: {}
  },
  mutations: {
    addBookmark(state, item) {
      if (!state.bookmarked[item.filename + item.id])
        state.bookmarked[item.filename + item.id] = item;
    },
    removeBookmark(state, item) {
      if (state.bookmarked[item.filename + item.id])
        delete state.bookmarked[item.filename + item.id]
    },
    addToLastViewed(state, item) {
      let newState = state.lastViewed.filter((value) => {
        return value.filename + value.id != item.filename + item.id;
      });
      if (newState.length == 8) {
        newState.shift();
      }
      newState.unshift(item);
      state.lastViewed = newState;
    },
    removeAllBookmarks(state) {
      state.bookmarked = {};
    },
    removeLastViewed(state) {
      state.lastViewed = [];
    }
  },
  getters: {
    getBookmarks: state => {
      return state.bookmarked;
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
