<template>
    <div class="latest">
        <h2>Last Viewed Shanties</h2>
        <div class="shanties" v-if="$store.state.lastViewed.length">
            <router-link class="latest-link" v-for="latest in $store.state.lastViewed" v-bind:key="latest.filename+latest.id" :to="'/shanty/'+latest.filename+'/'+latest.id">
                <div class="latest-card">
                    <div class="card-name">{{latest.shanty}}</div>
                    <div class="card-credit">{{latest.credit}}</div>
                </div>
            </router-link>
        </div>
        <div class="empty-wrap" v-else>
            <div class="empty-indicator">
                <MusicNoteOff v-bind:size='40'/>
                <p>No latest shanties</p>
            </div>
        </div>
    </div>
</template>

<script>
import MusicNoteOff from 'vue-material-design-icons/MusicNoteOff.vue';
export default {
    components: {
        MusicNoteOff
    }
}
</script>

<style lang="scss" scoped>
.latest {
    margin: 1.5rem;
    h2 {
        font-weight: 300;
    }
    .latest-link {
        color: inherit;
        text-decoration: none;    
    }
    .latest-card {
        background: #25004b;
        border: 1px solid #00f6ff;
        padding: 1rem;
        margin: 1rem 0;
        border-radius: 5px;
        .card-name {
            font-size: 1.2rem;
            padding-bottom: 0.5rem;
        }
        .card-credit {
            color: #00f6ff;
        }
    }
    .empty-wrap {
        height: 18vh;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #00f6ff;
        
        .empty-indicator {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}
</style>