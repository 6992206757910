<template>
    <div>
        <div class="nav-container">
            <router-link to="/"><div class="nav-bt nbt-small"><Home/></div></router-link>
            <router-link to="/all"><div class="nav-bt nbt-small"><Alphabetical/></div></router-link>
            <router-link to="/search"><div class="nav-bt nbt-small"><Search/></div></router-link>
            <router-link to="/bookmarks"><div class="nav-bt nbt-small"><Bookmark/></div></router-link>
            <router-link to="/pirates"><div class="nav-bt nbt-small"><Pirate/></div></router-link>
            <!-- <router-link to="/settings"><div class="nav-bt nbt-small"><Cog/></div></router-link> -->
        </div>
    </div>
</template>

<script>
import Home from 'vue-material-design-icons/Home.vue';
import Alphabetical from 'vue-material-design-icons/AlphabeticalVariant.vue';
import Search from 'vue-material-design-icons/Magnify.vue';
import Bookmark from 'vue-material-design-icons/BookmarkMusic.vue';
import Pirate from 'vue-material-design-icons/Pirate.vue';
// import Cog from 'vue-material-design-icons/Cog.vue';

export default {
    components: {
        Home,
        Alphabetical,
        Search,
        Bookmark,
        Pirate
    }
}
</script>

<style lang="scss" scoped>
    .nav-container {
        background: #13002d;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        z-index: 10;
        box-shadow: 0 -2px 10px rgba(200, 200, 200, 0.5);
        border-top: 1px solid #00f6ff;
    }
    .nav-bt {
        color: #00f6ff;
    }
    .nbt-small {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .nbt-large-circle {
        height: 9vh;
        width: 9vh;
        margin-bottom: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 10px solid #fff;
        background: #efefef;
        z-index: 10;
    }
    .router-link-exact-active {
        .nav-bt {
            color: #ff00e9;
        }
    }
</style>