<template>
  <div id="app">
    <router-view id="main-view"/>
    <Navbar id="nav"/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  name: 'Home',
  components: {
    Navbar
  }
}
</script>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #13002d;
  color: #ff00e9;
  min-height: 100%;
  overflow: hidden;
}

#main-view {
  height: calc(100vh - 8vh);
  overflow-x: hidden;
  overflow-y: auto;
}

#nav {
  height: 8vh;
  z-index: 10;
}
</style>
