<template>
  <div class="home">
    <div class="header">
      <div class="left-placeholder"></div>
      <div class="header-text">Sea Shanties!</div>
      <div class="settings-link-wrap">
        <router-link to="/settings"><div class="nav-bt nbt-small"><Cog/></div></router-link>
      </div>
    </div>
    <LatestShanties/>
  </div>
</template>
<script>
import LatestShanties from '@/components/Latest.vue'
import Cog from 'vue-material-design-icons/Cog.vue';
export default {
  name: 'Home',
  components: {
    LatestShanties,
    Cog
  }
}
</script>

<style lang="scss" scoped>
  .home {
    .header {
      background: #25004b;
      color: #ff00e9;
      border-bottom: 1px solid #ff00e9;
      box-shadow: 0 0 10px 0 rgba(255,0,241,0.54);
      padding: 1.5rem 16px;
      font-size: 2rem;
      font-style: italic;
      text-align: center;
      display: flex;
      justify-content: center;
      .left-placeholder {
        flex: 1;
      }
      .settings-link-wrap {
        flex:1;
        .nav-bt {
          color: #ff00e9;
        }
        .nbt-small {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 100%;
        }
      }
    }
  }
</style>